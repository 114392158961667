module.exports = {
  siteTitle: 'Gatsby Starter Spectral', // <title>
  heading: 'Watson Neal',
  subHeading: 'SubHeading Goes Here',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/watsonneal',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:watson.neal@gmail.com',
    },
  ],
};
